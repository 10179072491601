import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import * as styles from "./index.module.scss";

import { IntercomProvider } from "react-use-intercom";

const PageLayout = ({ title, children }) => {
  return (
    <div
      className={
        title === "Features" ? styles.featuresPageLayout : styles.pageLayout
      }
    >
      <Helmet>
        {/* Page title */}
        <title>{title}</title>
        {/* Google Tag Manager script */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-HRGW1LV8SS"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-HRGW1LV8SS');
          `}
        </script>
      </Helmet>
      <IntercomProvider appId="upcp9err">{children}</IntercomProvider>
    </div>
  );
};

PageLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
};

export default PageLayout;
